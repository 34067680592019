import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import IndexView from '@/views/Index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
    // meta: { hideHeader: true, hideFooter: true }
  },
  {
    path: '/',
    name: 'Index',
    component: IndexView
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: () => import(/* webpackChunkName: "Imprint" */ '@/views/Imprint.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "Privacy" */ '@/views/Privacy.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  }
})

export default router
