import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'bootstrap';
// import 'bootstrap-icons'

import '@/styles/app.scss';

const firebaseConfig = {
  apiKey: "AIzaSyCTthLSWSD3HcWCBzbrWWQiND0dfweBglw",
  authDomain: "reparatur-schmitt.firebaseapp.com",
  projectId: "reparatur-schmitt",
  storageBucket: "reparatur-schmitt.appspot.com",
  messagingSenderId: "626740571194",
  appId: "1:626740571194:web:082c35edab9305c25bd903",
  measurementId: "G-TBQYFSVVT1"
};

import { initializeApp } from "firebase/app";

initializeApp(firebaseConfig)

createApp(App).use(router).mount('#app')
