<template>
  <section class="image-section">
    <div class="fullScreenSection">
      <div class="fullScreenImage">
        <div class="text-center title fadeIn pb-lg-3">
          <h2>Fenster und Türen</h2>
          <p class="mb-0 px-3">
            Ihr Spezialist für Instandsetzung und Reparatur von Fenstern und Türen im Landkreis Schweinfurt und Bad Kissingen
          </p>
        </div>
      </div>
    </div>
  </section>

  <section id="services" class="service-section pt-5">
    <div class="container content fadeIn">
      <div class="row">
        <div class="col-12 text-center">
          <h2>Dienstleistungen</h2>
        </div>
      </div>
      <div class="row pt-4">
        <!-- Mobile -->
        <div class="d-lg-none col">
          <div class="accordion" id="accordionExample">
            <div class="card">
              <div class="card-header" id="headingOne">
                <h3 class="mb-0">
                  <button class="btn btn-block text-left text-secondary" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Reparatur von Fenster & Türen
                  </button>
                </h3>
              </div>

              <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div class="card-body">
                  <div class="text-center pb-2">
                    <span>Wir reparieren Fenster und Türen aus Stahl, Aluminium, Kunststoff oder Holz.</span>
                  </div>

                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      Erneuerung und Reparatur von Schlösser & Beschlägen
                    </li>
                    <li class="list-group-item">Verzogene Türen richten</li>
                    <li class="list-group-item">Hängende Fenster neu verklotzen</li>
                    <li class="list-group-item">Dichtungserneuerung</li>
                    <li class="list-group-item">Befestigung von losen Rahmen</li>
                    <li class="list-group-item">und mehr...</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingTwo">
                <h3 class="mb-0">
                  <button class="btn btn-block text-left text-secondary collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Wartungsumfang
                  </button>
                </h3>
              </div>
              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                <div class="card-body">
                  <h4 class="h5">Fenster</h4>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">Beschlagseinstellungen</li>
                    <li class="list-group-item">Gängigkeit wiederherstellen</li>
                    <li class="list-group-item">
                      Schmierarbeiten von beweglichen Teilen
                    </li>
                    <li class="list-group-item">Dichtungen prüfen/pflegen</li>
                    <li class="list-group-item">Anpressdruck prüfen</li>
                  </ul>
                  <h4 class="h5 pt-3">Türen</h4>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">Schloß & Verrigelungen einstellen</li>
                    <li class="list-group-item">Türbänder einstellen</li>
                    <li class="list-group-item">
                      Bewegliche Teile & Kontaktflächen schmieren
                    </li>
                    <li class="list-group-item">(Boden-)Dichtungen prüfen/pflegen</li>
                    <li class="list-group-item">Anpressdruck prüfen</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Tablet, Desktop -->
        <div class="d-none d-lg-block col-6 pr-4">
          <div class="d-flex flex-column align-items-center">
            <h3 class="pb-3">Reparatur von Fenster & Türen</h3>
            <div>
              <p class="text-center">
                Wir reparieren Fenster und Türen aus Stahl, Aluminium,
                Kunststoff oder Holz.
              </p>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  Erneuerung und Reparatur von Schlösser & Beschlägen
                </li>
                <li class="list-group-item">Verzogene Türen richten</li>
                <li class="list-group-item">Hängende Fenster neu verklotzen</li>
                <li class="list-group-item">Dichtungserneuerung</li>
                <li class="list-group-item">Befestigung von losen Rahmen</li>
                <li class="list-group-item">und mehr...</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="d-none d-lg-block col-6 pl-4">
          <div class="d-flex flex-column">
            <h3 class="pb-3 text-center">Wartungsumfang</h3>
            <h4 class="h5">Fenster</h4>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">Beschlagseinstellungen</li>
              <li class="list-group-item">Gängigkeit wiederherstellen</li>
              <li class="list-group-item">
                Schmierarbeiten von beweglichen Teilen
              </li>
              <li class="list-group-item">Dichtungen prüfen/pflegen</li>
              <li class="list-group-item">Anpressdruck prüfen</li>
            </ul>
            <h4 class="h5 pt-3">Türen</h4>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">Schloß & Verrigelungen einstellen</li>
              <li class="list-group-item">Türbänder einstellen</li>
              <li class="list-group-item">
                Bewegliche Teile & Kontaktflächen schmieren
              </li>
              <li class="list-group-item">(Boden-)Dichtungen prüfen/pflegen</li>
              <li class="list-group-item">Anpressdruck prüfen</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="contact" class="contact-section py-3">
    <div class="container content fadeIn">
      <div class="row pb-3">
        <div class="col text-center">
          <h2>Kontakt</h2>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <h5 class="mb-1">Telefonnummer</h5>
          <div>0171/3177854</div>
        </div>
        <div class="col-6 px-0">
          <h5 class="mb-1">E-Mail</h5>
          <div>info@reparatur-schmitt.de</div>
        </div>
      </div>

      <div class="row py-3 mt-4 bg-light" style="border: 1px solid #dee2e6; border-radius: 5px;">
        <div class="col">
          <h5>Kontaktformular</h5>

          <!-- Kontaktanfrage abgesendet -->
          <email-was-sent 
            v-if="wasEmailSent"
            :title="'E-Mail wurde versendet!'" />

          <!-- Kontaktformular -->
          <form
            v-if="!wasEmailSent"
            class="pt-2"
            @submit.prevent="sendMail">
            <div class="form-row">
              <div class="form-group col-6">
                <label for="doorsOrWindows">
                  Türen oder Fenster
                  <span class="pl-1 text-danger">*</span>
                </label>
                <select v-model="form.type" id="doorsOrWindows" class="form-control" required>
                  <!-- <option :value="void 0" selected>Auswählen...</option> -->
                  <option :value="Types.TÜREN">Tür(en)</option>
                  <option :value="Types.FENSTER">Fenster</option>
                  <option :value="Types.TÜRENUNDFENSTER">Tür(en) und Fenster</option>
                </select>
              </div>
              <div class="form-group col-6">
                <label for="material">
                  Material
                  <span class="pl-1 text-danger">*</span>
                </label>
                <select v-model="form.material" id="material" class="form-control" required>
                  <!-- <option :value="void 0" selected>Auswählen...</option> -->
                  <option :value="Materials.ALUMINIUM">Aluminium</option>
                  <option :value="Materials.STAHL">Stahl</option>
                  <option :value="Materials.KUNSTSTOFF">Kunststoff</option>
                  <option :value="Materials.HOLZ">Holz</option>
                </select>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-6">
                <label for="foreName">
                  Vorname
                  <span class="pl-1 text-danger">*</span>
                </label>
                <input v-model="form.foreName" type="text" class="form-control" id="foreName" required>
              </div>
              <div class="form-group col-6">
                <label for="lastName">
                  Nachname
                  <span class="pl-1 text-danger">*</span>
                </label>
                <input v-model="form.lastName" type="text" class="form-control" id="lastName" required>
              </div>
              <div class="form-group col-6">
                <label for="phoneNumber">
                  Telefonnummer
                  <span class="pl-1 text-danger">*</span>
                </label>
                <input v-model="form.phoneNumber" type="phone" class="form-control" id="phoneNumber" required>
              </div>
              <div class="form-group col-6">
                <label for="email">
                  E-Mail
                  <span class="pl-1 text-danger">*</span>
                </label>
                <input v-model="form.email" type="email" class="form-control" id="email" required>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-12">
                <label for="subject">
                  Betreff/Thema
                  <span class="pl-1 text-danger">*</span>
                </label>
                <input v-model="form.subject" type="text" class="form-control" id="subject" required>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="text">
                  Anliegen
                  <span class="pl-1 text-danger">*</span>
                </label>
                <textarea v-model="form.text" class="form-control" id="text" rows="4" required></textarea>
              </div>
            </div>

            <div class="row">
              <div class="col-auto d-flex align-items-end">
                <div class="text-danger small font-italic">
                  * Pflichtfelder
                </div>
              </div>
              <div class="col text-right">
                <button :disabled="emailAlreadySent || isLoading" type="submit" class="btn btn-secondary">
                  <span 
                    v-if="isLoading"
                    class="spinner-grow spinner-grow-sm" 
                    role="status" 
                    aria-hidden="true" />
                  Senden
                </button>
                <div 
                  v-if="emailAlreadySent" 
                  class="text-danger small font-italic pt-2">
                  Es kann nur alle 30min eine E-Mail über das Kontaktformular versendet werden!
                </div>
              </div>
            </div>

            <div 
              v-if="emailError"
              class="alert alert-danger mt-3 mb-0" 
              role="alert">
              Beim Versenden der E-Mail über das Kontaktformular ist ein Fehler aufgetreten!
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import EmailWasSent from '@/components/EmailWasSent.vue';
import { Materials, Types } from '@/scripts/interfaces';
import { sendContactMail } from '@/scripts/sendMail';
import Cookies from 'js-cookie'

export default {
  components: {
    EmailWasSent,
  },

  data () {
    return {
      Materials,
      Types,
      form: {},
      wasEmailSent: false,
      isLoading: false,
      emailAlreadySent: false,
      emailError: false,
    }
  },

  mounted () {
    this.emailAlreadySent = this.getAlreadySent()
  },

  methods: {
    async sendMail () {
      try {
        if (this.emailAlreadySent === true) return // Email darf nur alle 30 Minuten gesendet werden
        if (!this.form.type || !this.form.material || !this.form.foreName || !this.form.lastName || !this.form.phoneNumber || !this.form.email || !this.form.subject || !this.form.text) {
          return
        }
        this.emailError = false
        this.isLoading = true
        await sendContactMail(this.form)
        Cookies.set('alreadySent', 'true', { expires: 1/48 })
        this.isLoading = false
        this.wasEmailSent = true
        this.emailAlreadySent = true
        setTimeout(() => {
          this.emailAlreadySent = this.getAlreadySent()
        }, 1800000)
      } catch (e) {
        this.emailError = true
        console.error(e)
      }
    },
    getAlreadySent () {
      const alreadySent = Cookies.get('alreadySent')
      return JSON.parse((alreadySent == undefined ? 'false' : alreadySent))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/app.scss';

  .image-section {
    .fullScreenImage {
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      // Bild
      background-image: url("@/assets/main.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      .title {
        color: whitesmoke;
        font-family: "Source Sans Pro", sans-serif;
        text-shadow: 0 0 5px #333;
        h2 {
          font-weight: bold;
          font-size: 3.5em;
          line-height: 1.2;
        }
        p {
          font-size: 1.75em;
        }
      }
    }
  }

  .service-section {
    .card-header {
      background-color: lighten($color: #e9e9e9, $amount: 5);
      button {
        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .contact-section {
    .container {
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
    }

    textarea {
      resize: none;
    }
  }
</style>