<template>
  <!-- Mobile -->
  <nav class="navbar d-lg-none navbar-expand-lg navbar-light justify-content-center justify-content-sm-between w-100">
    <!-- Für Index Anchor -->
    <!-- <a v-if="$route.name === 'Index'" class="navbar-brand" href="#page1">
      <img
        src="@/assets/logo.png"
        alt=""
        width="330"
        @load="setNavbarHeight"
      />
    </a>
    <a v-else class="navbar-brand" @click="$router.push({ name: 'Index', hash: '#page1' })">
      <img
        src="@/assets/logo.png"
        alt=""
        width="330"
        @load="setNavbarHeight"
      />
    </a> -->
    <router-link :to="{ name: 'Index', hash: '' }" class="navbar-brand">
      <img
        src="@/assets/logo.png"
        alt=""
        width="330"
        @load="setNavbarHeight" />
    </router-link>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#anchorTags" aria-controls="anchorTags" aria-expanded="false" aria-label="Menü ausklappen">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse ml-4" id="anchorTags">
      <ul class="navbar-nav ml-auto my-3 text-left">
        <li class="nav-item" :class="{ 'active': $route.name === 'Index' && $route.hash === '' }">
          <router-link :to="{ name: 'Index', hash: '' }" class="nav-link">Start</router-link>
        </li>
        <li class="nav-item px-lg-5" :class="{ 'active': $route.name === 'Index' && $route.hash === '#services' }">
          <router-link :to="{ name: 'Index', hash: '#services' }" class="nav-link">Dienstleistungen</router-link>
        </li>
        <li class="nav-item" :class="{ 'active': $route.name === 'Index' && $route.hash === '#contact' }">
          <router-link :to="{ name: 'Index', hash: '#contact' }" class="nav-link">Kontakt</router-link>
        </li>
      </ul>
    </div>
  </nav>

  <!-- Desktop -->
  <nav class="navbar d-none d-lg-flex navbar-expand-lg navbar-light w-100">
    <div class="container">
      <div class="row">
        <div class="col d-flex align-items-center">
          <!-- Für Index Anchor -->
          <router-link :to="{ name: 'Index', hash: '' }">
            <img
              src="@/assets/logo.png"
              alt=""
              width="330"
              @load="setNavbarHeight" />
          </router-link>

          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#anchorTags" aria-controls="anchorTags" aria-expanded="false" aria-label="Menü ausklappen">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse ml-3 ml-lg-5 pt-lg-2" id="anchorTags">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item" :class="{ 'active': $route.name === 'Index' && $route.hash === '' }">
                <router-link :to="{ name: 'Index', hash: '' }" class="nav-link">Start</router-link>
              </li>
              <li class="nav-item px-lg-5" :class="{ 'active': $route.name === 'Index' && $route.hash === '#services' }">
                <router-link :to="{ name: 'Index', hash: '#services' }" class="nav-link">Dienstleistungen</router-link>
              </li>
              <li class="nav-item" :class="{ 'active': $route.name === 'Index' && $route.hash === '#contact' }">
                <router-link :to="{ name: 'Index', hash: '#contact' }" class="nav-link">Kontakt</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  mounted () {
    document.addEventListener('resize', this.setNavbarHeight)
  },
  methods: {
    setNavbarHeight () {
      const navbars = document.getElementsByClassName('navbar')
      const height = navbars[0].offsetHeight > 0 ? navbars[0].offsetHeight : navbars[1].offsetHeight
      document.documentElement.style.setProperty('--sectionPaddingTop', `${height}px`)
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  background-color: #e9e9e9;
  box-shadow: 0px 0px 4px 2px #ececec;

  .nav-link {
    line-height: normal;
  }
}
</style>