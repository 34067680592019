<template>
    <Header />
    
    <router-view />
    
    <Footer />

    <vue-cookie-accept-decline
      :disableDecline="true"
      elementId="cookie-law"
      position="bottom-right"
      type="floating"
    >
      <template #message>
        Diese Website verwendet Cookies, um Ihnen die bestmögliche Nutzung unserer Website zu ermöglichen.
      </template>

      <template #acceptContent>Verstanden</template>
    </vue-cookie-accept-decline>
</template>

<script>
import Header from '@/components/Header.vue';
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Header,
    Footer,
    VueCookieAcceptDecline
  }
}
</script>

<style lang="scss">
@import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';

#app {
  font-family: 'Source Sans Pro',sans-serif;
  color: #333;
  line-height: 1.55;
  font-size: 16px;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // background-color: #2c3e50;
  .section {
    .content {
      &.container {
        padding-top: 3rem;
        padding-bottom: 3rem;
      }
    }
  }

  .fp-overflow {
    height: 100% !important;
  }
}
</style>

