<template>
  <footer id="footer">
    <div class="container">
      <div class="row border-top py-4">
        <div class="col">
          <div class="row small">
            <div class="col-7 col-md-8 text-left">
              <span class="pr-3 copyright">&copy;{{ currentYear }}&nbsp;Reparatur Schmitt</span>
              <span class="pl-0 pl-md-2 pr-2">
                <router-link :to="{ name: 'Privacy' }">Datenschutz</router-link>
              </span>
              <span>
                <router-link :to="{ name: 'Imprint' }">Impressum</router-link>
              </span>
            </div>
            <div class="col-5 col-md-4 d-flex justify-content-end">
              <a href="https://www.my-hammer.de/auftragnehmer/reparatur-schmitt" target="_blank" class="d-flex align-items-center">
                <img src="@/assets/myhammer.png" height="20" />
                <span class="pl-1">zu MyHammer</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import myHammerLogo from '@/assets/myhammer.png';

export default {
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/app.scss';

footer {
  position: absolute;
  width: 100%;

  .row {
    div {
      text-align: center;
    }
  }

  a {
    color: #333;
    text-decoration: none;

    &:hover {
      color: #333;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 768px) {
    .copyright {
      line-break: anywhere;
    }
  }
}
</style>
